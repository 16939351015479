import React from 'react';

export const getMcReportData = (
  report,
  campaign,
  clickDetails,
  unsubscribes
) => {
  return {
    id: report.id,
    campaign_title: report.campaign_title,
    type: report.type,
    list_id: report.list_id,
    list_is_active: report.list_is_active,
    list_name: report.list_name,
    subject_line: report.subject_line,
    preview_text: report.preview_text,
    emails_sent: report.emails_sent,
    abuse_reports: report.abuse_reports,
    unsubscribed: report.unsubscribed,
    send_time: report.send_time,
    hard_bounces: report.bounces.hard_bounces,
    soft_bounces: report.bounces.soft_bounces,
    syntax_errors: report.bounces.syntax_errors,
    forwards_count: report.forwards.forwards_count,
    forwards_opens: report.forwards.forwards_opens,
    opens_total: report.opens.opens_total,
    unique_opens: report.opens.unique_opens,
    open_rate: report.opens.open_rate,
    last_open: report.opens.last_open,
    clicks_total: report.clicks.clicks_total,
    unique_clicks: report.clicks.unique_clicks,
    click_rate: report.clicks.click_rate,
    last_click: report.clicks.last_click,
    campaign_id: campaign.id,
    campaign_web_id: campaign.web_id,
    campaign_archive_url: campaign.archive_url,
    campaign_long_archive_url: campaign.long_archive_url,
    urls_clicked: clickDetails.urls_clicked.map((url) => ({
      id: url.id,
      url: url.url,
      total_clicks: url.total_clicks,
      click_percentage: url.click_percentage,
      unique_clicks: url.unique_clicks,
      unique_click_percentage: url.unique_click_percentage,
      last_click: url.last_click,
      campaign_id: url.campaign_id,
    })),
    unsubscribes: unsubscribes.unsubscribes.map((unsub) => ({
      email_address: unsub.email_address,
      FNAME: unsub.merge_fields.FNAME,
      LNAME: unsub.merge_fields.LNAME,
      reason: unsub.reason,
      timestamp: unsub.timestamp,
    })),
  };
};

const McReportData = ({ report, campaign, clickDetails, unsubscribes }) => {
  const reportData = getMcReportData(
    report,
    campaign,
    clickDetails,
    unsubscribes
  );

  return (
    <div>
      <h2>Report Details</h2>
      <pre>{JSON.stringify(reportData, null, 2)}</pre>
    </div>
  );
};

export default McReportData;
