import React from 'react';
import { Accordion, Table } from 'react-bootstrap';

const UnsubscribedAccordion = ({ unsubscribes }) => {
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };
  return (
    <Accordion className='custom-accordion'>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>Unsubscribed: {unsubscribes.length}</Accordion.Header>
        <Accordion.Body>
          <Table
            size='sm'
            style={{ border: 'none', color: 'black', width: 'auto' }}
          >
            <thead>
              <tr style={{ backgroundColor: '#e8e8e8' }}>
                <th
                  style={{
                    textAlign: 'left',
                    border: 'none',
                    color: 'black',
                    padding: '12px',
                  }}
                >
                  Email Address
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    border: 'none',
                    color: 'black',
                    padding: '12px',
                  }}
                >
                  First Name
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    border: 'none',
                    color: 'black',
                    padding: '12px',
                  }}
                >
                  Last Name
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    border: 'none',
                    color: 'black',
                    padding: '12px',
                  }}
                >
                  Last Changed
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    border: 'none',
                    color: 'black',
                    padding: '12px',
                  }}
                >
                  Reason
                </th>
              </tr>
            </thead>
            <tbody>
              {unsubscribes.map((unsubscribe, index) => {
                const firstName =
                  unsubscribe.merge_fields?.FNAME || unsubscribe.FNAME || '';
                const lastName =
                  unsubscribe.merge_fields?.LNAME || unsubscribe.LNAME || '';
                const rowStyle = {
                  backgroundColor: index % 2 === 0 ? 'white' : '#f9f9f9',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  lineHeight: '1.5',
                };
                return (
                  <tr key={index} style={rowStyle}>
                    <td
                      style={{
                        textAlign: 'left',
                        border: 'none',
                        color: 'black',
                        padding: '12px',
                      }}
                    >
                      {unsubscribe.email_address}
                    </td>
                    <td
                      style={{
                        textAlign: 'left',
                        border: 'none',
                        color: 'black',
                        padding: '12px',
                      }}
                    >
                      {firstName}
                    </td>
                    <td
                      style={{
                        textAlign: 'left',
                        border: 'none',
                        color: 'black',
                        padding: '12px',
                      }}
                    >
                      {lastName}
                    </td>
                    <td
                      style={{
                        textAlign: 'left',
                        border: 'none',
                        color: 'black',
                        padding: '12px',
                      }}
                    >
                      {formatDate(unsubscribe.timestamp)}
                    </td>
                    <td
                      style={{
                        textAlign: 'left',
                        border: 'none',
                        color: 'black',
                        padding: '12px',
                      }}
                    >
                      {unsubscribe.reason}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default UnsubscribedAccordion;
