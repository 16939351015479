import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const McSearchTableRow = ({ report }) => {
  const formatSendTime = (sendTime) => {
    const date = new Date(sendTime);
    return isNaN(date.getTime()) ? 'Not Sent' : date;
  };

  const sendDate = formatSendTime(report.campaign.send_time);

  return (
    <tr>
      <td>{report.campaign.settings.title}</td>
      <td>
        <Link to={`/mcreports/${report.campaign.id}`}>
          {report.campaign.settings.subject_line}
        </Link>
      </td>
      <td>{report.campaign.emails_sent.toLocaleString()}</td>
      <td>
        {sendDate === 'Not Sent' ? (
          'Not Sent'
        ) : (
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-${report.campaign.id}`}>
                {sendDate.toLocaleTimeString()}
              </Tooltip>
            }
          >
            <span>{sendDate.toLocaleDateString()}</span>
          </OverlayTrigger>
        )}
      </td>
    </tr>
  );
};

export default McSearchTableRow;
