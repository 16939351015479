import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFoundScreen = () => {
  return (
    <Container>
      <Row className='justify-content-md-center'>
        <Col md={6} className='text-center'>
          <h1>404</h1>
          <h2>Page Not Found</h2>
          <p>The page you are looking for does not exist.</p>
          <Link to='/' className='btn btn-primary'>
            Go Home
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundScreen;
