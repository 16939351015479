import { apiSlice } from './apiSlice';
import { SAVED_MC_REPORTS_URL } from '../constants';

export const mcSavedReportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addMcSavedReport: builder.mutation({
      query: (report) => ({
        url: SAVED_MC_REPORTS_URL,
        method: 'POST',
        body: report,
      }),
    }),
    getMcSavedReports: builder.query({
      query: () => ({
        url: SAVED_MC_REPORTS_URL,
      }),
      keepUnusedDataFor: 5,
    }),
    getMyMcSavedReports: builder.query({
      query: () => ({
        url: `${SAVED_MC_REPORTS_URL}/myreports`,
      }),
      keepUnusedDataFor: 5,
    }),
    getMcSavedReportById: builder.query({
      query: (id) => ({
        url: `${SAVED_MC_REPORTS_URL}/${id}`,
      }),
      keepUnusedDataFor: 5,
    }),
    deleteMcSavedReport: builder.mutation({
      query: (id) => ({
        url: `${SAVED_MC_REPORTS_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
    addCommentToMcSavedReport: builder.mutation({
      query: ({ reportId, comment }) => ({
        url: `${SAVED_MC_REPORTS_URL}/${reportId}/comments`,
        method: 'POST',
        body: comment,
      }),
    }),
    deleteCommentFromMcSavedReport: builder.mutation({
      query: ({ reportId, commentId }) => ({
        url: `${SAVED_MC_REPORTS_URL}/${reportId}/comments/${commentId}`,
        method: 'DELETE',
      }),
    }),
    editCommentInMcSavedReport: builder.mutation({
      query: ({ reportId, commentId, comment }) => ({
        url: `${SAVED_MC_REPORTS_URL}/${reportId}/comments/${commentId}`,
        method: 'PUT',
        body: { comment },
      }),
    }),
  }),
});

export const {
  useAddMcSavedReportMutation,
  useGetMcSavedReportsQuery,
  useGetMcSavedReportByIdQuery,
  useGetMyMcSavedReportsQuery,
  useDeleteMcSavedReportMutation,
  useAddCommentToMcSavedReportMutation,
  useDeleteCommentFromMcSavedReportMutation,
  useEditCommentInMcSavedReportMutation,
} = mcSavedReportsApiSlice;
