import React from 'react';
import { Table, Button } from 'react-bootstrap';
import McSearchTableRow from './McSearchTableRow';

const McSearchResultsTable = ({ campaigns, sortConfig, handleSort }) => {
  const headers = [
    { key: 'campaign_title', label: 'Campaign Title' },
    { key: 'subject_line', label: 'Subject Line' },
    { key: 'emails_sent', label: 'Sent' },
    { key: 'send_time', label: 'Date' },
  ];

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' ▲' : ' ▼';
    }
    return ' ▲▼';
  };

  return (
    <>
      {Array.isArray(campaigns) && campaigns.length > 0 && (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header.key}>
                  {header.label}
                  {(header.key === 'emails_sent' ||
                    header.key === 'send_time') && (
                    <Button
                      variant='link'
                      onClick={() => handleSort(header.key)}
                    >
                      {getSortIndicator(header.key)}
                    </Button>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {campaigns.map((campaign) => (
              <McSearchTableRow key={campaign.campaign.id} report={campaign} />
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default McSearchResultsTable;
