import { useLocation, Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

const Subheader = () => {
  const location = useLocation();

  return (
    <Nav className='mb-3'>
      <Nav.Item>
        <Nav.Link
          as={Link}
          to='/'
          className={location.pathname === '/' ? 'active-link' : ''}
        >
          Reports
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          to='/mcchart'
          className={location.pathname === '/mcchart' ? 'active-link' : ''}
        >
          Chart
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          to='/mcsavedreports'
          className={
            location.pathname === '/mcsavedreports' ? 'active-link' : ''
          }
        >
          Saved
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          to='/mcsearch'
          className={location.pathname === '/mcsearch' ? 'active-link' : ''}
        >
          Search
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default Subheader;
