import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './slices/apiSlice';
import mcSearchReducer from './slices/mcSearchSlice';
import authReducer from './slices/authSlice';
import mcReportsReducer from './slices/mcReportsApiSlice';

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    mcSearch: mcSearchReducer,
    auth: authReducer,
    mcReports: mcReportsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
