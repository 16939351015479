import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  useGetMcSavedReportsQuery,
  useDeleteMcSavedReportMutation,
} from '../../slices/mcSavedReportsApiSlice';
import Subheader from '../../components/Subheader';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import McSavedReportListTableRow from '../../components/mc/McSavedReportListTableRow';
import CustomTable from '../../components/CustomTable';

const McSavedReportListScreen = () => {
  const {
    data: reports,
    isLoading,
    error,
    refetch,
  } = useGetMcSavedReportsQuery();

  const [deleteMcSavedReport] = useDeleteMcSavedReportMutation();

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure')) {
      try {
        await deleteMcSavedReport(id).unwrap();
        toast.success('Report deleted successfully', { autoClose: 2000 });
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const [hoveredCreatedAtId, setHoveredCreatedAtId] = useState(null);
  const [hoveredSendTimeId, setHoveredSendTimeId] = useState(null);

  const handleCreatedAtMouseOver = (reportId) => {
    setHoveredCreatedAtId(reportId);
  };

  const handleCreatedAtMouseOut = () => {
    setHoveredCreatedAtId(null);
  };

  const handleSendTimeMouseOver = (reportId) => {
    setHoveredSendTimeId(reportId);
  };

  const handleSendTimeMouseOut = () => {
    setHoveredSendTimeId(null);
  };

  const headers = [
    'DELETE',
    'ACCOUNT',
    'DATE SAVED',
    'SUBJECT / PREVIEW',
    'DATE SENT',
    'SENT',
    'OPENED',
    'CLICKED',
  ];

  const renderRow = (report) => (
    <McSavedReportListTableRow
      key={report._id}
      report={report}
      deleteHandler={deleteHandler}
      handleCreatedAtMouseOver={handleCreatedAtMouseOver}
      handleCreatedAtMouseOut={handleCreatedAtMouseOut}
      handleSendTimeMouseOver={handleSendTimeMouseOver}
      handleSendTimeMouseOut={handleSendTimeMouseOut}
      hoveredCreatedAtId={hoveredCreatedAtId}
      hoveredSendTimeId={hoveredSendTimeId}
    />
  );

  return (
    <>
      <Subheader />
      <h1>Saved MailChimp Reports</h1>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <CustomTable
            headers={headers}
            rows={reports
              .slice()
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))}
            renderRow={renderRow}
          />
        </>
      )}
    </>
  );
};

export default McSavedReportListScreen;
