import { useState } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';

const Header = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();
  const [expanded, setExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      sessionStorage.clear(); // Clear session storage
      navigate('/login');
    } catch (err) {
      console.error(err);
    }
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleDropdownToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleNavItemClick = () => {
    setExpanded(false);
  };

  const handleUsernameClick = () => {
    setExpanded(false);
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header>
      <Navbar
        bg='dark'
        variant='dark'
        expand='lg'
        expanded={expanded}
        onToggle={handleToggle}
      >
        <Container>
          {userInfo && userInfo.isApproved ? (
            <LinkContainer to='/'>
              <Navbar.Brand>LSA Report Data</Navbar.Brand>
            </LinkContainer>
          ) : (
            <LinkContainer to='/login'>
              <Navbar.Brand>LSA Report Data</Navbar.Brand>
            </LinkContainer>
          )}
          <Navbar.Toggle
            aria-controls='basic-navbar-nav'
            onClick={handleToggle}
          />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              {userInfo ? (
                <NavDropdown
                  title={
                    <span onClick={handleUsernameClick}>{userInfo.name}</span>
                  }
                  id='username'
                  show={expanded ? true : dropdownOpen}
                  onToggle={handleDropdownToggle}
                >
                  {userInfo.isApproved ? (
                    <>
                      <LinkContainer to='/profile'>
                        <NavDropdown.Item onClick={handleNavItemClick}>
                          Profile
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to='/about'>
                        <NavDropdown.Item onClick={handleNavItemClick}>
                          About
                        </NavDropdown.Item>
                      </LinkContainer>
                    </>
                  ) : (
                    <LinkContainer to='/profile'>
                      <NavDropdown.Item onClick={handleNavItemClick}>
                        Profile
                      </NavDropdown.Item>
                    </LinkContainer>
                  )}
                  {userInfo && userInfo.isAdmin && (
                    <>
                      <NavDropdown.Divider />
                      <LinkContainer to='/admin/users'>
                        <NavDropdown.Item onClick={handleNavItemClick}>
                          Users
                        </NavDropdown.Item>
                      </LinkContainer>
                    </>
                  )}
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link onClick={handleNavItemClick}>
                    <FaUser /> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
