import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice';
import { MC_REPORTS_URL, MC_CAMPAIGNS_URL } from '../constants';

// Inject endpoints into the apiSlice
export const mcReportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Query to fetch Mailchimp reports
    getMcReports: builder.query({
      // set default values for offset and count
      query: ({ offset = 0, count = 50 }) =>
        `${MC_REPORTS_URL}?offset=${offset}&count=${count}`,
      keepUnusedDataFor: 600, // Keep unused data for 10 minutes
      transformResponse: (response) => response.reports,
    }),
    // Query to fetch a specific Mailchimp report by ID
    getMcReportById: builder.query({
      query: (id) => `${MC_REPORTS_URL}/${id}`,
      keepUnusedDataFor: 600, // Keep unused data for 10 minutes
    }),
    // Query to fetch click details of a specific Mailchimp report by ID
    getMcReportClickDetailsById: builder.query({
      query: (id) => `${MC_REPORTS_URL}/${id}/click-details`,
      keepUnusedDataFor: 600, // Keep unused data for 10 minutes
    }),
    // Query to fetch unsubscribes of a specific Mailchimp report by ID
    getMcReportUnsubscribesById: builder.query({
      query: (id) => `${MC_REPORTS_URL}/${id}/unsubscribes`,
      keepUnusedDataFor: 600, // Keep unused data for 10 minutes
    }),
    // Query to fetch Mailchimp campaigns
    getMcCampaigns: builder.query({
      query: () => MC_CAMPAIGNS_URL,
      keepUnusedDataFor: 600, // Keep unused data for 10 minutes
    }),
    // Query to fetch a specific Mailchimp campaign by ID
    getMcCampaignById: builder.query({
      query: (id) => `${MC_CAMPAIGNS_URL}/${id}`,
      keepUnusedDataFor: 600, // Keep unused data for 10 minutes
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetMcReportsQuery,
  useGetMcReportByIdQuery,
  useGetMcReportClickDetailsByIdQuery,
  useGetMcReportUnsubscribesByIdQuery,
  useGetMcCampaignsQuery,
  useGetMcCampaignByIdQuery,
} = mcReportsApiSlice;

// Define the initial state for the slice
const initialState = {
  reports: [],
  status: 'idle',
  error: null,
  showAboveNum: 16, // Default value
  scrollPosition: 0,
  offset: 0,
};

// Create a slice for additional state management
const mcReportsSlice = createSlice({
  name: 'mcReports',
  initialState,
  reducers: {
    setShowAboveNum(state, action) {
      state.showAboveNum = action.payload;
    },
    setListName(state, action) {
      state.listName = action.payload;
    },
    addReports(state, action) {
      state.reports = [...state.reports, ...action.payload];
    },
    resetReports(state) {
      state.reports = [];
      state.offset = 0;
    },
    setScrollPosition(state, action) {
      state.scrollPosition = action.payload;
    },
    setOffset(state, action) {
      state.offset = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        mcReportsApiSlice.endpoints.getMcReports.matchFulfilled,
        (state, action) => {
          state.reports = [...state.reports, ...action.payload];
          state.status = 'succeeded';
        }
      )
      .addMatcher(
        mcReportsApiSlice.endpoints.getMcReports.matchPending,
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        mcReportsApiSlice.endpoints.getMcReports.matchRejected,
        (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        }
      );
  },
});

// Export actions and reducer
export const {
  setShowAboveNum,
  setListName,
  addReports,
  resetReports,
  setScrollPosition,
  setOffset,
} = mcReportsSlice.actions;
export default mcReportsSlice.reducer;
