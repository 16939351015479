import React from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import McChart from '../../components/mc/McChart';
import Subheader from '../../components/Subheader';
import Message from '../../components/Message';

const McChartScreen = () => {
  const allReports = useSelector((state) => state.mcReports.reports);

  if (!allReports || allReports.length === 0) {
    return (
      <Container>
        <Subheader />
        <h1>Recent Reports Chart</h1>
        <Message variant='danger'>
          Reports must first be loaded on reports screen
        </Message>
      </Container>
    );
  }

  return (
    <Container>
      <Subheader />
      <h1>Recent Reports Chart</h1>
      <p>
        This chart pulls the reports that are loaded to the Reports page. If you
        choose All/Actual or select a different list under Show Additional
        Filters, then the chart will update accordingly.
      </p>
      <McChart reports={allReports} />
    </Container>
  );
};

export default McChartScreen;
