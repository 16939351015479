import React, { useEffect } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const ReportComment = ({
  comment,
  onEdit,
  onDelete,
  isEditing,
  onSave,
  onCancel,
  commentText,
  setCommentText,
}) => {
  useEffect(() => {
    if (isEditing) {
      setCommentText(comment.comment);
    }
  }, [isEditing, comment.comment, setCommentText]);

  return (
    <Card key={comment._id} className='mb-3'>
      <Card.Body>
        {isEditing ? (
          <Form>
            <Form.Group controlId='comment'>
              <Form.Control
                as='textarea'
                rows={3}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
            </Form.Group>
            <Button
              variant='primary'
              onClick={() => onSave(comment._id)}
              className='me-2'
            >
              Save
            </Button>
            <Button variant='secondary' onClick={onCancel}>
              Cancel
            </Button>
          </Form>
        ) : (
          <>
            <Card.Text>{comment.comment}</Card.Text>
            <Card.Subtitle className='mb-2 text-muted d-flex justify-content-between align-items-center'>
              <small>
                <strong>{comment.name}</strong> -{' '}
                {formatDate(comment.createdAt)}
              </small>
              <span>
                <Button
                  variant='secondary'
                  onClick={() => onEdit(comment._id, comment.comment)}
                  className='me-2'
                  size='sm'
                >
                  <FaEdit />
                </Button>
                <Button
                  variant='danger'
                  onClick={() => onDelete(comment._id)}
                  size='sm'
                >
                  <FaTrash />
                </Button>
              </span>
            </Card.Subtitle>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ReportComment;
