import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store.js';
import './assets/styles/bootstrap.custom.css';
import './assets/styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PrivateRoute from './components/PrivateRoute.jsx';
import AdminRoute from './components/AdminRoute.jsx';
import ApprovedRoute from './components/ApprovedRoute.jsx';
import ProtectedRoute from './components/ProtectedRoute.jsx';
import RegisterScreen from './screens/RegisterScreen.jsx';
import LoginScreen from './screens/LoginScreen.jsx';
import ProfileScreen from './screens/ProfileScreen.jsx';
import UserListScreen from './screens/admin/UserListScreen';
import UserEditScreen from './screens/admin/UserEditScreen';
import McReportListScreen from './screens/mc/McReportListScreen.jsx';
import McReportScreen from './screens/mc/McReportScreen.jsx';
import McSavedReportListScreen from './screens/mc/McSavedReportListScreen.jsx';
import McSavedReportScreen from './screens/mc/McSavedReportScreen.jsx';
import McSearchScreen from './screens/mc/McSearchScreen.jsx';
import McChartScreen from './screens/mc/McChartScreen.jsx';
import NotFoundScreen from './screens/NotFoundScreen';
import AboutScreen from './screens/AboutScreen';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route path='/login' element={<LoginScreen />} />
      <Route path='/register' element={<RegisterScreen />} />

      <Route element={<ProtectedRoute />}>
        <Route path='' element={<PrivateRoute />}>
          <Route index={true} path='/' element={<McReportListScreen />} />
          <Route path='about' element={<AboutScreen />} />
          <Route path='profile' element={<ProfileScreen />} />

          {/* Admin */}
          <Route path='' element={<AdminRoute />}>
            <Route path='/admin/users' element={<UserListScreen />} />
            <Route path='/admin/users/:id/edit' element={<UserEditScreen />} />
          </Route>

          {/* Approved MailChimp Reports */}
          <Route path='' element={<ApprovedRoute />}>
            {/* */}
            <Route path='/mcreports' element={<McReportListScreen />} />
            <Route path='/mcreports/:id' element={<McReportScreen />} />
            <Route
              path='/mcsavedreports'
              element={<McSavedReportListScreen />}
            />
            <Route
              path='/mcsavedreports/:id'
              element={<McSavedReportScreen />}
            />
            <Route path='/mcsearch' element={<McSearchScreen />} />
            <Route path='/mcchart' element={<McChartScreen />} />
          </Route>
        </Route>
      </Route>
      <Route path='*' element={<NotFoundScreen />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider
        router={router}
        future={{
          v7_normalizeFormMethod: true,
          v7_startTransition: true,
          v7_relativeSplatPath: true,
          v7_fetcherPersist: true,
          v7_partialHydration: true,
          v7_skipActionErrorRevalidation: true,
        }}
      />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
