import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const McReportListTableRow = ({ report, index }) => {
  const sendDate = new Date(report.send_time);

  return (
    <tr key={`${report.id}-${index}`}>
      <td>{report.campaign_title}</td>
      <td>
        <Link to={`/mcreports/${report.id}`}>{report.subject_line}</Link>
      </td>
      <td>
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-${report.id}`}>
              {sendDate.toLocaleTimeString()}
            </Tooltip>
          }
        >
          <span>{sendDate.toLocaleDateString()}</span>
        </OverlayTrigger>
      </td>
      <td>{report.emails_sent?.toLocaleString() || 'N/A'}</td>
    </tr>
  );
};

McReportListTableRow.propTypes = {
  report: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default McReportListTableRow;
