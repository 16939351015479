import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetMcReportsQuery,
  addReports,
  resetReports,
  setScrollPosition,
  setOffset,
} from '../../slices/mcReportsApiSlice';
import Subheader from '../../components/Subheader';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import McFilterForm, {
  getShowAboveNumTitle,
} from '../../components/mc/McFilterForm';
import McReportListTableRow from '../../components/mc/McReportListTableRow';
import CustomTable from '../../components/CustomTable';

const McReportListScreen = () => {
  const dispatch = useDispatch();
  const { reports, showAboveNum, error, scrollPosition, offset } = useSelector(
    (state) => state.mcReports
  );

  const [loadingMore, setLoadingMore] = useState(false);
  const [query, setQuery] = useState('');
  const [filteredReports, setFilteredReports] = useState([]);

  const listRef = useRef(null);
  const isFirstRender = useRef(true);

  const countOff = 50;

  // Fetch reports with pagination
  const {
    data,
    error: queryError,
    isLoading,
  } = useGetMcReportsQuery({
    offset,
    count: countOff,
  });

  // Set initial state before executing the query
  useEffect(() => {
    if (isFirstRender.current && reports.length === 0) {
      dispatch(setOffset(0)); // Set initial offset
      dispatch(resetReports()); // Reset reports
      isFirstRender.current = false;
    }
  }, [dispatch, reports.length]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      // Only add new data if it is not already in the reports
      const newReports = data.filter(
        (report) => !reports.some((r) => r.id === report.id)
      );
      if (newReports.length > 0) {
        dispatch(addReports(newReports));
      }
      setLoadingMore(false);
    }
  }, [data, dispatch, reports]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo(0, scrollPosition);
    }
  }, [scrollPosition]);

  const loadMoreReports = useCallback(() => {
    setLoadingMore(true);
    dispatch(setOffset(offset + countOff));
  }, [offset, dispatch]);

  const handleQueryChange = useCallback((e) => {
    setQuery(e.target.value);
  }, []);

  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(resetReports());
    },
    [dispatch]
  );

  const handleScroll = useCallback(() => {
    if (listRef.current) {
      dispatch(setScrollPosition(listRef.current.scrollTop));
    }
  }, [dispatch]);

  const uniqueListNames = useMemo(() => {
    return [...new Set(reports.map((report) => report.list_name))];
  }, [reports]);

  const showAboveNumTitle = getShowAboveNumTitle(showAboveNum);

  useEffect(() => {
    if (reports.length === 0 && !isLoading) {
      dispatch(setOffset(0)); // Ensure offset is set to 0
    }
  }, [reports.length, isLoading, dispatch]);

  if (isLoading && offset === 0) return <Loader />;
  if (error || queryError)
    return (
      <Message variant='danger'>
        {error?.message || queryError?.message}
      </Message>
    );

  return (
    <div ref={listRef} onScroll={handleScroll}>
      <Subheader />
      <h1>Current Mailchimp Reports</h1>
      <McFilterForm
        reports={reports}
        showAboveNum={showAboveNum}
        query={query}
        handleQueryChange={handleQueryChange}
        uniqueListNames={uniqueListNames}
        showAboveNumTitle={showAboveNumTitle}
        handleFormSubmit={handleFormSubmit}
        setFilteredReports={setFilteredReports}
      />
      <CustomTable
        headers={['Campaign Title', 'Subject Line', 'Send Date', 'Emails Sent']}
        rows={filteredReports}
        renderRow={(report, index) => (
          <McReportListTableRow
            key={`${report.id}-${index}`}
            report={report}
            index={index}
          />
        )}
      />
      {loadingMore && <Loader />}
      <Button onClick={loadMoreReports} className='mt-3' disabled={loadingMore}>
        {loadingMore ? 'Loading...' : 'Load More'}
      </Button>
    </div>
  );
};

export default McReportListScreen;
