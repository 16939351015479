import React from 'react';
import { Link } from 'react-router-dom';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';

const McSavedReportListTableRow = ({ report, deleteHandler }) => {
  const sendDate = new Date(report.send_time);
  const createdAt = new Date(report.createdAt);

  return (
    <tr key={report._id}>
      <td>
        <Button
          variant='danger'
          onClick={() => deleteHandler(report._id)}
          aria-label='Delete Report'
        >
          <FaTrash />
        </Button>
      </td>
      <td>{report.user?.name}</td>
      <td>
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-${report._id}`}>
              {createdAt.toLocaleTimeString()}
            </Tooltip>
          }
        >
          <span>{createdAt.toLocaleDateString()}</span>
        </OverlayTrigger>
      </td>
      <td>
        <Link to={`/mcsavedreports/${report._id}`}>
          {report.subject_line}{' '}
          {report.preview_text !== '' && `/ ${report.preview_text}`}
        </Link>
      </td>
      <td>
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-${report._id}`}>
              {sendDate.toLocaleTimeString()}
            </Tooltip>
          }
        >
          <span>{sendDate.toLocaleDateString()}</span>
        </OverlayTrigger>
      </td>
      <td>{report.emails_sent?.toLocaleString() || 'N/A'}</td>
      <td>{report.unique_opens?.toLocaleString() || 'N/A'}</td>
      <td>{report.clicks_total?.toLocaleString() || 'N/A'}</td>
    </tr>
  );
};

export default McSavedReportListTableRow;
