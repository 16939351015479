import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Legend,
} from 'recharts';
import { Tabs, Tab, Container } from 'react-bootstrap';

const CustomXAxisTick = ({ x, y, payload }) => {
  const text =
    payload.value.length > 10
      ? `${payload.value.slice(0, 10)}...`
      : payload.value;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor='end'
        fill='#666'
        transform='rotate(-90)'
        style={{ fontSize: '12px', whiteSpace: 'pre-wrap' }}
      >
        {text}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const report = payload[0].payload;
    return (
      <div
        className='custom-tooltip'
        style={{
          backgroundColor: 'white',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <p className='label'>{report.subject_line}</p>
        <p className='intro'>{`Emails Sent: ${report.emails_sent.toLocaleString()}`}</p>
        <p className='intro'>{`Unique Opens: ${report.opens.unique_opens.toLocaleString()}`}</p>
        <p className='intro'>{`Total Clicks: ${report.clicks.clicks_total.toLocaleString()}`}</p>
        <p className='intro'>{`Unsubscribed: ${report.unsubscribed}`}</p>
        <p className='intro'>{`Sent on: ${new Date(
          report.send_time
        ).toLocaleString()}`}</p>
      </div>
    );
  }

  return null;
};

const McChart = ({ reports = [] }) => {
  const navigate = useNavigate();
  const [key, setKey] = useState('opens');

  // Get filter values from Redux state
  const listName =
    useSelector((state) => state.mcReports.listName) || 'All Reports';
  const showAboveNum =
    useSelector((state) => state.mcReports.showAboveNum) || 0;

  // Filter reports based on Redux state filters
  const filteredReports = reports.filter((report) => {
    if (listName !== 'All Reports' && report.list_name !== listName) {
      return false;
    }
    if (showAboveNum !== null && report.emails_sent < showAboveNum) {
      return false;
    }
    return true;
  });

  const handleBarClick = (data) => {
    if (data && data.id) {
      navigate(`/mcreports/${data.id}`);
    }
  };

  return (
    <Container>
      <Tabs
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className='mb-3'
      >
        <Tab eventKey='opens' title='Opens'>
          <ResponsiveContainer width='100%' height={500}>
            <ComposedChart
              data={filteredReports}
              margin={{ top: 20, right: 30, left: 20, bottom: 100 }} // Adjust bottom margin for space
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='campaign_title'
                tick={<CustomXAxisTick />}
                interval={0}
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend layout='horizontal' verticalAlign='top' align='center' />
              <Bar
                dataKey='opens.unique_opens'
                name='Unique Opens'
                fill='#8884d8'
                onClick={(data) => handleBarClick(data)}
              />
              <Line
                type='monotone'
                dataKey='clicks.clicks_total'
                name='Total Clicks'
                stroke='#ff7300'
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Tab>
        <Tab eventKey='unsubscribed' title='Unsubscribed'>
          <ResponsiveContainer width='100%' height={500}>
            <ComposedChart
              data={filteredReports}
              margin={{ top: 20, right: 30, left: 20, bottom: 100 }} // Adjust bottom margin for space
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='campaign_title'
                tick={<CustomXAxisTick />}
                interval={0}
              />
              <YAxis tickFormatter={(tick) => Math.round(tick)} />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey='unsubscribed'
                fill='#ff0000'
                onClick={(data) => handleBarClick(data)}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default McChart;
