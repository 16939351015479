import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

const CustomTable = ({ headers, rows, renderRow }) => {
  return (
    <Table striped bordered hover responsive className='table-sm'>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>{rows.map((row, index) => renderRow(row, index))}</tbody>
    </Table>
  );
};

CustomTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.array.isRequired,
  renderRow: PropTypes.func.isRequired,
};

export default CustomTable;
