import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ListGroup, Button, Form, Container } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useGetMcSavedReportByIdQuery,
  useAddCommentToMcSavedReportMutation,
  useDeleteCommentFromMcSavedReportMutation,
  useEditCommentInMcSavedReportMutation,
} from '../../slices/mcSavedReportsApiSlice';
import ReportComment from '../../components/ReportComment';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import Subheader from '../../components/Subheader';
import { formatDateAndTime, calculateCombinedUrls } from '../../utils/helpers';
import ExcludeUrlsForm from '../../components/ExcludeUrlsForm';
import UnsubscribedAccordion from '../../components/UnsubscribedAccordion';
import McReportDetails from '../../components/mc/McReportDetails';
import McReportTable from '../../components/mc/McReportTable';

const McSavedReportScreen = () => {
  const { id: reportId } = useParams();
  const navigate = useNavigate();
  const {
    data: report = {},
    isLoading,
    error,
    refetch,
  } = useGetMcSavedReportByIdQuery(reportId);

  const [addCommentToMcSavedReport] = useAddCommentToMcSavedReportMutation();
  const [editCommentInMcSavedReport] = useEditCommentInMcSavedReportMutation();
  const [deleteCommentFromMcSavedReport] =
    useDeleteCommentFromMcSavedReportMutation();

  const [comments, setComments] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [newCommentText, setNewCommentText] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);

  useEffect(() => {
    if (report.comments) {
      setComments(report.comments);
    }
  }, [report]);

  const handleEditClick = (commentId, currentText) => {
    setEditingCommentId(commentId);
    setCommentText(currentText);
    setEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      await editCommentInMcSavedReport({
        reportId: report._id,
        commentId: editingCommentId,
        comment: commentText,
      }).unwrap();
      setEditMode(false);
      setEditingCommentId(null);
      setCommentText('');
      refetch();
      toast.success('Comment updated successfully', { autoClose: 2000 });
    } catch (error) {
      console.error('Failed to update comment:', error);
      toast.error('Failed to update comment');
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setEditingCommentId(null);
    setCommentText('');
  };

  const handleDeleteClick = async (commentId) => {
    if (window.confirm('Are you sure')) {
      try {
        await deleteCommentFromMcSavedReport({
          reportId: report._id,
          commentId,
        }).unwrap();
        refetch();
        toast.success('Comment deleted successfully', { autoClose: 2000 });
      } catch (error) {
        console.error('Failed to delete comment:', error);
        toast.error('Failed to delete comment');
      }
    }
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    try {
      await addCommentToMcSavedReport({
        reportId: report._id,
        comment: { comment: newCommentText },
      }).unwrap();
      setNewCommentText('');
      refetch();
      toast.success('Comment added successfully', { autoClose: 2000 });
    } catch (error) {
      console.error('Failed to add comment:', error);
      toast.error('Failed to add comment');
    }
  };

  const [excludeUrls, setExcludeUrls] = useState('');

  const combinedUrls = useMemo(
    () => calculateCombinedUrls(report, excludeUrls, 'saved'),
    [report, excludeUrls]
  );

  return (
    <>
      <Subheader />
      <ToastContainer />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error.message}</Message>
      ) : (
        <>
          <Container>
            <h1 className='my-1 h5'>{report.campaign_title}</h1>

            <p>
              Sent on {formatDateAndTime(report.send_time)} | Saved by{' '}
              {report.user?.name || 'Unknown'} on{' '}
              {formatDateAndTime(report.createdAt)}
            </p>

            <Link
              className='btn btn-light my-3 me-3'
              onClick={() => navigate(-1)}
            >
              Go Back
            </Link>

            <Link to={`/mcreports/${report.id}`} className='btn btn-light my-3'>
              Current MailChimp Report (realtime data)
            </Link>

            <McReportTable
              report={report}
              combinedUrls={combinedUrls}
              id={report.campaign_id}
              useCurrentDate={false}
              screenType='saved'
            />

            <ListGroup variant='flush'>
              <ListGroup.Item className='mb-4'>
                <Link
                  to={`https://us18.admin.mailchimp.com/analytics/reports/overview?id=${report.id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='custom-link'
                >
                  MailChimp Report Page
                </Link>
                <Link
                  to={`https://us19.admin.mailchimp.com/reports/activity/unsubscribed?id=${report.campaign_web_id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='custom-link'
                >
                  MailChimp Unsubscribed Page
                </Link>
                <UnsubscribedAccordion
                  unsubscribes={report.unsubscribes}
                  campaignWebId={report.campaign_web_id}
                />
              </ListGroup.Item>

              <ExcludeUrlsForm
                excludeUrls={excludeUrls}
                setExcludeUrls={setExcludeUrls}
                report={report}
              />

              <McReportDetails
                report={report}
                combinedUrls={combinedUrls}
                screenType='saved'
              />

              <ListGroup.Item>
                {comments.map((comment) => (
                  <ReportComment
                    key={comment._id}
                    comment={comment}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                    isEditing={editMode && editingCommentId === comment._id}
                    onSave={handleSaveClick}
                    onCancel={handleCancelClick}
                    commentText={commentText}
                    setCommentText={setCommentText}
                  />
                ))}
              </ListGroup.Item>
            </ListGroup>
            <Form onSubmit={handleAddComment}>
              <Form.Group controlId='newComment'>
                <Form.Label>Add a Comment</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  value={newCommentText}
                  onChange={(e) => setNewCommentText(e.target.value)}
                />
              </Form.Group>
              <Button type='submit' variant='primary' className='mt-2'>
                Add Comment
              </Button>
            </Form>
          </Container>
        </>
      )}
    </>
  );
};

export default McSavedReportScreen;
