const formatDateAndTime = (dateString) => {
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const timeOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString(undefined, dateOptions);
  const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
  return `${formattedDate} at ${formattedTime}`;
};

const normalizeUrl = (url) => {
  return url.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/$/, '');
};

const calculateCombinedUrls = (clickDetails, excludeUrls, reportType) => {
  const urlMap = {};
  let totalClicks = 0;

  const excludeUrlsArray = excludeUrls
    .split(',')
    .map((url) => normalizeUrl(url.trim()));

  const urlsClicked =
    reportType === 'saved'
      ? clickDetails?.urls_clicked
      : clickDetails?.urls_clicked;

  if (clickDetails && urlsClicked) {
    urlsClicked.forEach((url) => {
      const normalizedUrl = normalizeUrl(url.url);
      if (excludeUrlsArray.includes(normalizedUrl)) {
        return;
      }
      if (!urlMap[normalizedUrl]) {
        urlMap[normalizedUrl] = { ...url };
      } else {
        urlMap[normalizedUrl].total_clicks += url.total_clicks;
      }
      totalClicks += url.total_clicks;
    });
  }

  const combinedUrlsArray = Object.values(urlMap);

  combinedUrlsArray.forEach((url) => {
    url.click_percentage = (url.total_clicks / totalClicks) * 100;
    if (isNaN(url.click_percentage)) {
      url.click_percentage = 0;
    }
  });

  combinedUrlsArray.sort((a, b) => b.total_clicks - a.total_clicks);

  return combinedUrlsArray;
};

const updateExcludeUrls = (excludeUrls, urlToUpdate, add) => {
  let updatedUrls = excludeUrls
    .split(',')
    .map((url) => url.trim())
    .filter((url) => url);

  if (add) {
    if (!updatedUrls.includes(urlToUpdate)) {
      updatedUrls.push(urlToUpdate);
    }
  } else {
    updatedUrls = updatedUrls.filter((url) => url !== urlToUpdate);
  }

  return updatedUrls.join(', ');
};

export { formatDateAndTime, calculateCombinedUrls, updateExcludeUrls };
