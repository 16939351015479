import React from 'react';
import { Tabs, Tab, Container } from 'react-bootstrap';
import flowChartImage from '../assets/state-management-flow.png'; // Import the flow chart image

const AboutScreen = () => {
  return (
    <Container>
      <h1>About LSA Report Data</h1>
      <p>
        We used to navigate through several MailChimp pages to get the
        information we needed. We then used a spreadsheet to calculate specific
        stats with data that we copied and pasted. It was a little tedious and
        pretty time consuming, so I decided to prepare this app to take care of
        many of those tasks by fetching data from MailChimp's API. Their API
        docs were really helpful for this task!
      </p>
      <p>
        We also wanted a way to easily remove certain links—like our own
        homepage—and recalculate the stats. Our homepage is in many of our email
        templates, and we sometimes want to calculate clicks that are outside of
        the template. It's helpful to be able to take other links out of our
        reports while preparing them as well. It's a simple form that removes
        links, allowing you to paste them in as comma-separated values.
      </p>
      <p>
        To keep things as simple as possible, it was important to make an app
        that would allow us to easily copy and paste unformatted data into a
        spreadsheet that we keep to track performance and also copy formatted
        reports to send to others in emails. The hardest part was preparing
        reports that looked nearly identical when sent from Outlook and Gmail,
        lol.
      </p>
      <Tabs defaultActiveKey='overview' id='about-tabs' className='mt-4'>
        <Tab eventKey='overview' title='Overview' className='custom-tab-pane'>
          <p>
            This is a full-stack MERN application that uses Redux Toolkit (RTK)
            for state management. Users can register, and after admin approval,
            they can search and review reports. They can also save reports and
            add/edit their own comments.
          </p>
          <p>
            The focus has really been to get properly unformatted tables to add
            to a spreadsheet and formatted reports that can be copied and sent
            as emails after blasts are sent.
          </p>
          <p>
            There's also a graph! I used recharts to prepare it, which was
            incredibly helpful&mdash;and easy.
          </p>
          <p>
            This app has evolved over time, but the most important info was here
            from the beginning. These are links to the older versions:
          </p>
          <p>
            2022: <br />
            <a
              href='https://lsa-mailchimp-stats.herokuapp.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              lsa-mailchimp-stats.herokuapp.com
            </a>
          </p>
          <p>
            2022 (different server): <br />
            <a
              href='https://mailchamp2022.herokuapp.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              mailchamp2022.herokuapp.com
            </a>
          </p>
          <p>
            2019: <br />
            <a
              href='https://mailchamp2019.herokuapp.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              mailchamp2019.herokuapp.com
            </a>
          </p>
        </Tab>
        <Tab eventKey='backend' title='Backend' className='custom-tab-pane'>
          <p>
            <strong>MongoDB:</strong> This app uses MongoDB as the database to
            store user information, saved Mailchimp reports, and comments. The
            app uses Mongoose models to define the structure of the data.
          </p>
          <p>
            <strong>Express:</strong> Express is used to create the server and
            define API routes. The app has various routes to handle different
            functionalities, including user registration, login, profile
            updates, and user management. It's also used to fetch and manage
            Mailchimp reports and comments.
          </p>
          <p>
            <strong>Node.js:</strong> Node is used to run the server and handle
            backend logic. Custom middleware functions are used for
            authentication (protect, admin, approved) and error handling.
          </p>
        </Tab>
        <Tab eventKey='frontend' title='Frontend' className='custom-tab-pane'>
          <p>
            <strong>React:</strong> This is a React app with components for
            displaying Mailchimp reports, user profiles, and comments. React
            Router is used for client-side routing, allowing users to navigate
            between most pages without reloading.
          </p>
          <p>
            <strong>Redux Toolkit (RTK):</strong> The app uses Redux Toolkit to
            create a centralized store for managing the application's state. The
            state is divided into slices, each responsible for a specific part
            of the state, such as authSlice for user authentication state,
            mcReportsApiSlice for fetching and managing Mailchimp reports, and
            mcSavedReportsApiSlice for managing saved Mailchimp reports and
            comments.
          </p>
          <p>
            <strong>Additional Dependencies:</strong> The app uses React
            Bootstrap for styling and responsive design, React Icons for UI
            icons, React Toastify for toast notifications, and React Router
            Bootstrap for integrating React Router with React Bootstrap
            components.
          </p>
          <p>
            <em>Take a look at a really simple flowchart in the last tab.</em>
          </p>
        </Tab>
        <Tab
          eventKey='features'
          title='Key Features'
          className='custom-tab-pane'
        >
          <p>
            <strong>User Authentication:</strong> Users can register and log in
            to the app. The authentication state is managed using authSlice.
            Users can update their profile information.
          </p>
          <p>
            <strong>Mailchimp Reports:</strong> The app fetches Mailchimp
            reports using the mcReportsApiSlice. Users can save reports for
            later reference using the mcSavedReportsApiSlice. Reports are
            displayed in a tabular format, with options to view detailed
            information.
          </p>
          <p>
            <strong>Comments:</strong> Users can add, edit, and delete comments
            on saved reports.
          </p>
        </Tab>
        <Tab
          eventKey='stateManagement'
          title='State Management Flow'
          className='custom-tab-pane'
        >
          <div className='d-flex justify-content-center'>
            <img
              src={flowChartImage}
              alt='State Management Flow Chart'
              className='img-fluid'
            />
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default AboutScreen;
