import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import Subheader from '../../components/Subheader';
import SearchForm from '../../components/SearchForm';
import ShowAboveNumSearchButton from '../../components/ShowAboveNumSearchButton';
import McSearchResultsTable from '../../components/mc/McSearchResultsTable';
import { searchCampaigns } from '../../slices/mcSearchSlice';
import useSort from '../../hooks/useSort';

const McSearchScreen = () => {
  const [query, setQuery] = useState('');
  const [showAboveNumSearch, setShowAboveNumSearch] = useState(() => {
    const savedShowAboveNumSearch = localStorage.getItem('showAboveNumSearch');
    return savedShowAboveNumSearch !== null
      ? Number(savedShowAboveNumSearch)
      : 0;
  });

  const handleShowAboveNumSearchChange = (num) => {
    setShowAboveNumSearch(num);
  };

  const { sortConfig, handleSort, sortedData } = useSort('send_time', 'desc');

  const dispatch = useDispatch();

  const { campaigns, isLoading, error } = useSelector(
    (state) => state.mcSearch
  );

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchCampaigns(query));
  };

  const filteredCampaigns = campaigns.filter(
    (campaign) =>
      !showAboveNumSearch || campaign.campaign.emails_sent > showAboveNumSearch
  );

  const sortedFilteredCampaigns = sortedData(filteredCampaigns);

  return (
    <Container>
      <Subheader />
      <h1>Search Mailchimp Campaigns</h1>
      <p>
        *This page is in beta. When we search for advertisers who have sent many
        blasts with us, this search will likely return incomplete results. It
        seems to be really good at picking up complete results for advertisers
        who have sent fewer blasts.
      </p>
      <Row className='align-items-center mb-3'>
        <Col md={10}>
          <SearchForm
            query={query}
            setQuery={setQuery}
            handleSearch={handleSearch}
          />
        </Col>
        <Col md={2}>
          <ShowAboveNumSearchButton
            switchNumbers={[0, 16]}
            onChange={handleShowAboveNumSearchChange}
          />
        </Col>
      </Row>
      {isLoading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}
      {Array.isArray(campaigns) && campaigns.length > 0 && (
        <McSearchResultsTable
          campaigns={sortedFilteredCampaigns}
          sortConfig={sortConfig}
          handleSort={handleSort}
        />
      )}
    </Container>
  );
};

export default McSearchScreen;
