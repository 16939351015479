import {
  useGetMcReportByIdQuery,
  useGetMcCampaignByIdQuery,
  useGetMcReportClickDetailsByIdQuery,
  useGetMcReportUnsubscribesByIdQuery,
} from '../slices/mcReportsApiSlice';

export const useMcReportsApiData = (id) => {
  const {
    data: report = {},
    error: reportError,
    isLoading: reportLoading,
  } = useGetMcReportByIdQuery(id);

  const {
    data: campaign,
    error: campaignError,
    isLoading: campaignLoading,
  } = useGetMcCampaignByIdQuery(id);

  const {
    data: clickDetails = {},
    error: clickDetailsError,
    isLoading: clickDetailsLoading,
  } = useGetMcReportClickDetailsByIdQuery(id);

  const {
    data: unsubscribes = [],
    error: unsubscribesError,
    isLoading: unsubscribesLoading,
  } = useGetMcReportUnsubscribesByIdQuery(id);

  return {
    report,
    reportError,
    reportLoading,
    campaign,
    campaignError,
    campaignLoading,
    clickDetails,
    clickDetailsError,
    clickDetailsLoading,
    unsubscribes,
    unsubscribesError,
    unsubscribesLoading,
  };
};
