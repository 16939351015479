import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  InputGroup,
  DropdownButton,
  Dropdown,
  Button,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListName as setListNameAction,
  setShowAboveNum as setShowAboveNumAction,
} from '../../slices/mcReportsApiSlice';

export const getShowAboveNumTitle = (showAboveNum) => {
  if (showAboveNum === 0) {
    return 'All';
  } else {
    return 'Actuals';
  }
};

const actualsNum = 16;

const McFilterForm = ({
  reports,
  showAboveNum,
  query,
  handleQueryChange,
  uniqueListNames,
  showAboveNumTitle,
  handleFormSubmit,
  setFilteredReports,
}) => {
  const dispatch = useDispatch();
  const currentListName = useSelector((state) => state.mcReports.listName);

  const [listName, setListName] = useState(currentListName || 'All Reports');
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);

  useEffect(() => {
    const savedListName = sessionStorage.getItem('listName') || 'All Reports';
    const savedShowAboveNum =
      sessionStorage.getItem('showAboveNum') || actualsNum;

    if (savedListName && savedListName !== currentListName) {
      setListName(savedListName);
      dispatch(setListNameAction(savedListName));
    }

    if (savedShowAboveNum && Number(savedShowAboveNum) !== showAboveNum) {
      dispatch(setShowAboveNumAction(Number(savedShowAboveNum)));
    }
  }, [dispatch, currentListName, showAboveNum]);

  useEffect(() => {
    setListName(currentListName || 'All Reports');
  }, [currentListName]);

  useEffect(() => {
    const filteredReports = reports
      .filter((report) => {
        if (listName !== 'All Reports' && report.list_name !== listName) {
          return false;
        }
        if (showAboveNum !== null && report.emails_sent < showAboveNum) {
          return false;
        }
        if (
          query &&
          !report.campaign_title.toLowerCase().includes(query.toLowerCase()) &&
          !report.subject_line.toLowerCase().includes(query.toLowerCase())
        ) {
          return false;
        }
        return true;
      })
      .sort((a, b) => new Date(b.send_time) - new Date(a.send_time)); // Sort by date in descending order

    setFilteredReports(filteredReports);
  }, [reports, listName, showAboveNum, query, setFilteredReports]);

  const toggleAdditionalFilters = () => {
    setShowAdditionalFilters(!showAdditionalFilters);
  };

  const handleListNameChangeWithStorage = (name) => {
    setListName(name);
    sessionStorage.setItem('listName', name);
    dispatch(setListNameAction(name));
  };

  const handleShowAboveNumChangeWithStorage = (num) => {
    dispatch(setShowAboveNumAction(num));
    sessionStorage.setItem('showAboveNum', num);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Form.Group controlId='filterOptions'>
        <Row>
          <Col md={12}>
            <InputGroup>
              <Form.Control
                type='text'
                placeholder='Filter by campaign title or subject line'
                value={query}
                onChange={handleQueryChange}
              />
              <DropdownButton
                as={InputGroup.Append}
                variant='outline-secondary'
                title={showAboveNumTitle}
                id='dropdown-show-above-num'
                onSelect={(e) => handleShowAboveNumChangeWithStorage(Number(e))}
              >
                <Dropdown.Item eventKey={0}>
                  {getShowAboveNumTitle(0)}
                </Dropdown.Item>
                <Dropdown.Item eventKey={actualsNum}>
                  {getShowAboveNumTitle(actualsNum)}
                </Dropdown.Item>
              </DropdownButton>
            </InputGroup>
          </Col>
        </Row>
        <Button
          variant='link'
          onClick={toggleAdditionalFilters}
          style={{ textDecoration: 'none', color: 'gray' }}
        >
          {showAdditionalFilters
            ? 'Hide Additional Filters'
            : 'Show Additional Filters'}
        </Button>
        {showAdditionalFilters && (
          <Row>
            <Col md={12}>
              <InputGroup>
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant='outline-secondary'
                  title={`Filter: ${listName}`}
                  id='input-group-dropdown-2'
                  onSelect={(e) => handleListNameChangeWithStorage(e)}
                >
                  <Dropdown.Item eventKey='All Reports'>
                    All Reports
                  </Dropdown.Item>
                  {uniqueListNames.map((listName, index) => (
                    <Dropdown.Item key={index} eventKey={listName}>
                      {listName}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            </Col>
          </Row>
        )}
      </Form.Group>
    </Form>
  );
};

export default McFilterForm;
