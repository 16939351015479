import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FaCopy } from 'react-icons/fa';
import DOMPurify from 'dompurify';

const McReportDetails = ({ report = {}, combinedUrls = [], screenType }) => {
  const totalClicks = useMemo(
    () => combinedUrls.reduce((acc, url) => acc + (url.total_clicks || 0), 0),
    [combinedUrls]
  );

  const emailsSent = report.emails_sent || report.emailsSent || 0;
  const uniqueOpens = report.opens?.unique_opens || report.unique_opens || 0;
  const openRate = uniqueOpens / emailsSent;

  const calculatedClickRate = useMemo(() => {
    if (uniqueOpens === 0) return 0;
    return (totalClicks / uniqueOpens) * 100;
  }, [totalClicks, uniqueOpens]);

  // This is the HTML template for the Mailchimp report details table. It's sanitized using DOMPurify before rendering, and it's really hard to work with! I'm trying to get this to copy and paste as closely as possible to the original Mailchimp report details, from earlier versions of this app, but also so that it looks similar when coming from either Outlook or Gmail. It could be better.

  const tableHtml = `
    <table>
      <tr>
        <td style="width: 100%; font-family: 'Trebuchet MS'; color: rgb(31, 73, 125); padding-left: 4px; border: 0; text-align: left; margin-bottom: 16px;">
          ${
            report.subject_line
          }: Lighting&Sound America Marketing Campaign; ${new Date(
    report.send_time
  ).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })}
        </td>
      </tr>
      ${
        report.preview_text
          ? `<tr><td style="width: 100%; font-family: 'Trebuchet MS'; color: rgb(31, 73, 125); padding-left: 4px; border: 0; text-align: left; margin-bottom: 16px;">Preview: ${report.preview_text}</td></tr>`
          : ''
      }
    </table>
    <table>
      <tbody>
        <tr>
          <td style="width: 120px; height: 36px; font-family: 'Trebuchet MS'; color: rgb(31, 73, 125); padding-left: 8px; vertical-align: bottom; border: 0; text-align: left;">Total Sent:</td>
          <td style="width: 120px; height: 36px; font-family: 'Trebuchet MS'; color: rgb(31, 73, 125); padding-left: 8px; vertical-align: bottom; border: 0; text-align: left;">${emailsSent.toLocaleString()}</td>
        </tr>
        <tr>
          <td style="width: 120px; height: 36px; font-family: 'Trebuchet MS'; color: rgb(31, 73, 125); padding-left: 8px; vertical-align: bottom; border: 0; text-align: left;">Opened:</td>
          <td style="width: 120px; height: 36px; font-family: 'Trebuchet MS'; color: rgb(31, 73, 125); padding-left: 8px; vertical-align: bottom; border: 0; text-align: left;">${uniqueOpens.toLocaleString()}</td>
          <td style="width: 120px; height: 36px; font-family: 'Trebuchet MS'; color: rgb(31, 73, 125); padding-left: 8px; vertical-align: bottom; border: 0; text-align: left;">${(
            openRate * 100
          ).toFixed(2)}%</td>
        </tr>
        <tr>
          <td style="width: 120px; height: 36px; font-family: 'Trebuchet MS'; color: rgb(31, 73, 125); padding-left: 8px; vertical-align: bottom; border: 0; text-align: left;">Total Clicks:</td>
          <td style="width: 120px; height: 36px; font-family: 'Trebuchet MS'; color: rgb(31, 73, 125); padding-left: 8px; vertical-align: bottom; border: 0; text-align: left;">${totalClicks.toLocaleString()}</td>
          <td style="width: 120px; height: 36px; font-family: 'Trebuchet MS'; color: rgb(31, 73, 125); padding-left: 8px; vertical-align: bottom; border: 0; text-align: left;">${calculatedClickRate.toFixed(
            2
          )}%</td>
        </tr>
        <tr>
          <td style="width: 120px; height: 36px; font-family: 'Trebuchet MS'; color: rgb(31, 73, 125); padding-left: 8px; vertical-align: bottom; border: 0; text-align: left;">Clickthroughs:</td>
        </tr>
      </tbody>
    </table>
    <table style="min-width: 480px; max-width: 920px;">
      <thead>
        <tr>
          <td style="width: 640px; min-width: 360px; max-width: 800px; border: 0; height: 48px; border-top: 1pt solid rgb(222, 221, 220); border-bottom: 1pt solid rgb(222, 221, 220); font-family: 'Trebuchet MS'; background: rgb(246, 246, 244); border-right: 2px solid white; padding-left: 8px; font-size: 100%; text-align: left;">URL</td>
          <th style="width: 120px; max-width: 120px; min-width: 120px; border: 0; height: 48px; border-top: 1pt solid rgb(222, 221, 220); border-bottom: 1pt solid rgb(222, 221, 220); font-family: 'Trebuchet MS'; background: rgb(246, 246, 244); padding-right: 8px; font-size: 100%; text-align: right; color: black;">Total Clicks</th>
        </tr>
      </thead>
      <tbody>
        ${combinedUrls
          .map(
            (url) => `
          <tr>
            <td style="width: 640px; min-width: 360px; max-width: 800px; border: 0; height: 48px; overflow: hidden; border-bottom: 1pt dotted rgb(222, 221, 220); font-family: 'Trebuchet MS'; padding-left: 8px; font-size: 100%; text-align: left;">
              <a href="${
                url.url
              }" target="_blank" rel="noopener noreferrer" style="color: rgb(17, 85, 204); text-decoration: none;">${
              url.url
            }</a>
            </td>
            <td style="width: 120px; max-width: 120px; min-width: 120px; border: 0; height: 48px; text-align: right; border-bottom: 1pt dotted rgb(222, 221, 220); font-family: 'Trebuchet MS'; padding-right: 8px; font-size: 100%; text-align: right; color: black; mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
            ${Math.round(url.total_clicks)?.toLocaleString() || '0'} (${
              url.click_percentage.toFixed(2) || '0'
            }%)</td>
          </tr>
        `
          )
          .join('')}
      </tbody>
    </table>
  `;

  const sanitizedHtml = DOMPurify.sanitize(tableHtml);

  const copyToClipboard = () => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = sanitizedHtml;
    document.body.appendChild(tempElement);

    const range = document.createRange();
    range.selectNodeContents(tempElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }

    document.body.removeChild(tempElement);
  };

  return (
    <div>
      <div style={{ marginTop: '24px' }}>
        <Button
          variant='secondary'
          className='btn-sm mb-3'
          aria-label='Copy Table'
          onClick={copyToClipboard}
        >
          <FaCopy />{' '}
          {screenType === 'realtime'
            ? 'Copy Realtime Report Details, Formatted for Email'
            : screenType === 'saved'
            ? 'Copy Saved Report Details, Formatted for Email'
            : 'Copy Report Details, Formatted for Email'}
        </Button>
      </div>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </div>
  );
};

export default McReportDetails;
