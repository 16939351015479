import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMcReportsApiData } from '../../hooks/useMcReportsApiData';

const McCampaignLink = ({ id, className = 'custom-link', children }) => {
  const { campaign, campaignError, campaignLoading } = useMcReportsApiData(id);

  if (campaignLoading) return <div>Loading...</div>;
  if (campaignError) return <div>Error: {campaignError.message}</div>;

  return (
    <Link
      // updating to campaign.long_archive_url from campaign.archive_url
      to={campaign.long_archive_url}
      target='_blank'
      rel='noopener noreferrer'
      className={className}
    >
      {children || campaign.long_archive_url}
    </Link>
  );
};

McCampaignLink.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default McCampaignLink;
