import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const searchCampaigns = createAsyncThunk(
  'mcSearch/searchCampaigns',
  async (query, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/mccampaigns/search?query=${query}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

const mcSearchSlice = createSlice({
  name: 'mcSearch',
  initialState: {
    campaigns: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchCampaigns.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchCampaigns.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaigns = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(searchCampaigns.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default mcSearchSlice.reducer;
