import React from 'react';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';

const SearchForm = ({ query, setQuery, handleSearch }) => {
  return (
    <Form onSubmit={handleSearch}>
      <InputGroup>
        <FormControl
          type='text'
          placeholder='Search Campaign Title'
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          name='searchQuery'
        />
        <Button variant='primary' type='submit'>
          Search
        </Button>
      </InputGroup>
    </Form>
  );
};

export default SearchForm;
