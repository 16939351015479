import { useState } from 'react';

const useSort = (initialKey, initialDirection, dataKey = 'campaign') => {
  const [sortConfig, setSortConfig] = useState({
    key: initialKey,
    direction: initialDirection,
  });

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = (data) => {
    return [...data].sort((a, b) => {
      if (a[dataKey][sortConfig.key] < b[dataKey][sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[dataKey][sortConfig.key] > b[dataKey][sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  return { sortConfig, handleSort, sortedData };
};

export default useSort;
