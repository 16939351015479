import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ShowAboveNumSearchButton = ({ onChange }) => {
  const switchNumbers = [0, 16];

  const [showAboveNumSearch, setShowAboveNumSearch] = useState(() => {
    const saved = localStorage.getItem('showAboveNumSearch');
    return saved !== null ? Number(saved) : switchNumbers[0];
  });

  useEffect(() => {
    localStorage.setItem('showAboveNumSearch', showAboveNumSearch);
    onChange(showAboveNumSearch);
  }, [showAboveNumSearch, onChange]);

  const handleShowAboveNumSearchChange = (num) => {
    setShowAboveNumSearch(num);
  };

  const getShowAboveNumSearchTitle = (num) => {
    if (num === 0) {
      return 'All';
    } else {
      return 'Actuals';
    }
  };

  const showAboveNumSearchTitle =
    getShowAboveNumSearchTitle(showAboveNumSearch);

  return (
    <DropdownButton
      id='dropdown-basic-button'
      title={showAboveNumSearchTitle}
      variant='outline-secondary'
    >
      {switchNumbers.map((num) => (
        <Dropdown.Item
          key={num}
          onClick={() => handleShowAboveNumSearchChange(num)}
        >
          {getShowAboveNumSearchTitle(num)}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

ShowAboveNumSearchButton.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ShowAboveNumSearchButton;
