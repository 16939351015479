import React, { useState, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ListGroup, Button, Container } from 'react-bootstrap';
import { useMcReportsApiData } from '../../hooks/useMcReportsApiData';
import { useAddMcSavedReportMutation } from '../../slices/mcSavedReportsApiSlice';
import Subheader from '../../components/Subheader';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { getMcReportData } from '../../components/mc/McReportData';
import { formatDateAndTime, calculateCombinedUrls } from '../../utils/helpers';
import ExcludeUrlsForm from '../../components/ExcludeUrlsForm';
import UnsubscribedAccordion from '../../components/UnsubscribedAccordion';
import McReportDetails from '../../components/mc/McReportDetails';
import McReportTable from '../../components/mc/McReportTable';

const McReportScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    report,
    reportError,
    reportLoading,
    campaign,
    campaignError,
    campaignLoading,
    clickDetails,
    clickDetailsError,
    clickDetailsLoading,
    unsubscribes,
    unsubscribesError,
    unsubscribesLoading,
  } = useMcReportsApiData(id);

  const [addMcSavedReport] = useAddMcSavedReportMutation();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [excludeUrls, setExcludeUrls] = useState('');

  const combinedUrls = useMemo(
    () => calculateCombinedUrls(clickDetails, excludeUrls, 'current'),
    [clickDetails, excludeUrls]
  );

  const totalClicks = combinedUrls.reduce(
    (acc, url) => acc + (url.total_clicks || 0),
    0
  );

  const saveReportHandler = async () => {
    if (!report.subject_line || !report.campaign_title) {
      alert('Subject and Campaign Title are required');
      return;
    }

    const McReportData = getMcReportData(
      report,
      campaign,
      clickDetails,
      unsubscribes
    );

    try {
      await addMcSavedReport(McReportData).unwrap();
      setSuccessMessage('Report saved successfully!');
      setTimeout(() => setSuccessMessage(''), 4000);
    } catch (err) {
      setErrorMessage(err?.data?.message || err.error);
      setTimeout(() => setErrorMessage(''), 4000);
    }
  };

  return (
    <>
      <Subheader />
      {reportLoading ||
      campaignLoading ||
      clickDetailsLoading ||
      unsubscribesLoading ? (
        <Loader />
      ) : reportError ? (
        <Message variant='danger'>{reportError.message}</Message>
      ) : campaignError ? (
        <Message variant='danger'>{campaignError.message}</Message>
      ) : clickDetailsError ? (
        <Message variant='danger'>{clickDetailsError.message}</Message>
      ) : unsubscribesError ? (
        <Message variant='danger'>{unsubscribesError.message}</Message>
      ) : (
        <>
          <Container>
            <h1 className='my-1 h5'>{report.campaign_title}</h1>
            <p>Sent on {formatDateAndTime(report.send_time)}</p>

            <Link className='btn btn-light my-3' onClick={() => navigate(-1)}>
              Go Back
            </Link>
            {successMessage && (
              <Message variant='success'>{successMessage}</Message>
            )}

            <McReportTable
              report={{ ...report, total_clicks: totalClicks }}
              combinedUrls={combinedUrls}
              id={id}
              useCurrentDate={true}
              screenType='realtime'
            />

            <ListGroup variant='flush'>
              <ListGroup.Item className='mb-4'>
                <Link
                  to={`https://us18.admin.mailchimp.com/analytics/reports/overview?id=${id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='custom-link'
                >
                  MailChimp Report Page
                </Link>
                <Link
                  to={`https://us19.admin.mailchimp.com/reports/activity/unsubscribed?id=${campaign.web_id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='custom-link'
                >
                  MailChimp Unsubscribed Page
                </Link>
                <UnsubscribedAccordion
                  unsubscribes={unsubscribes.unsubscribes}
                />
              </ListGroup.Item>

              <ExcludeUrlsForm
                excludeUrls={excludeUrls}
                setExcludeUrls={setExcludeUrls}
                report={report}
              />

              <McReportDetails
                report={{ ...report, total_clicks: totalClicks }}
                combinedUrls={combinedUrls}
                screenType='realtime'
              />
            </ListGroup>

            <Button
              className='mt-4 me-4'
              aria-label='Save Report'
              onClick={saveReportHandler}
            >
              Save Report
            </Button>

            <Button
              className='btn btn-light mt-4'
              aria-label='Go Back'
              onClick={() => navigate(-1)}
            >
              Go Back
            </Button>

            {successMessage && (
              <Message variant='success'>{successMessage}</Message>
            )}
            {errorMessage && <Message variant='danger'>{errorMessage}</Message>}
          </Container>
        </>
      )}
    </>
  );
};

export default McReportScreen;
