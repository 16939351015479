import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import Message from '../components/Message';
import { useLoginMutation } from '../slices/usersApiSlice';
import { setCredentials, logout } from '../slices/authSlice';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const location = useLocation();
  const redirect = location.state?.from || '/';

  useEffect(() => {
    if (userInfo) {
      if (userInfo.isApproved) {
        navigate(redirect);
      }
    }
  }, [navigate, redirect, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await login({ email, password }).unwrap();
      dispatch(setCredentials(res));
      if (res.isApproved) {
        navigate(redirect);
      }
    } catch (err) {
      setErrorMessage(err?.data?.message || 'Invalid username or password');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000); // Clear error message after 3 seconds
    }
  };

  const logoutHandler = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <FormContainer>
      <h1>Sign In</h1>
      {isLoading && <Loader />}
      {errorMessage && <Message variant='danger'>{errorMessage}</Message>}
      {userInfo && !userInfo.isApproved ? (
        <>
          <Message variant='warning'>
            Waiting for your account to be approved.
          </Message>
          <Button variant='primary' onClick={logoutHandler}>
            Logout
          </Button>
        </>
      ) : (
        <Form onSubmit={submitHandler}>
          <Form.Group className='my-2' controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group className='my-2' controlId='password'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button disabled={isLoading} type='submit' variant='primary'>
            Sign In
          </Button>
        </Form>
      )}
    </FormContainer>
  );
};

export default LoginScreen;
