import React, { useState, useEffect } from 'react';
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { updateExcludeUrls } from '../utils/helpers';

const ExcludeUrlsForm = ({ excludeUrls, setExcludeUrls, report }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleExcludeUrlsChange = (e) => {
    setExcludeUrls(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);

    const updatedUrls = updateExcludeUrls(
      excludeUrls,
      'lightingandsoundamerica.com',
      checked
    );
    setExcludeUrls(updatedUrls);
  };

  useEffect(() => {
    const urls = excludeUrls.split(',').map((url) => url.trim());
    if (
      report?.list_name === 'Industry Marketing blasts' &&
      !urls.includes('lightingandsoundamerica.com')
    ) {
      const updatedUrls = updateExcludeUrls(
        excludeUrls,
        'lightingandsoundamerica.com',
        true
      );
      setExcludeUrls(updatedUrls);
      setIsChecked(true);
    } else {
      setIsChecked(urls.includes('lightingandsoundamerica.com'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return (
    <Form>
      <Row>
        <Col xs={6}>
          <OverlayTrigger
            overlay={
              <Tooltip id='tooltip-top'>
                Checked by default for industry marketing blasts
              </Tooltip>
            }
          >
            <Form.Group controlId='excludeLightingAndSoundAmerica'>
              <Form.Check
                type='checkbox'
                label='Exclude lightingandsoundamerica.com'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId='excludeUrls'>
            <Form.Control
              type='text'
              placeholder='Enter URLs to exclude, separated by commas'
              value={excludeUrls}
              onChange={handleExcludeUrlsChange}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default ExcludeUrlsForm;
