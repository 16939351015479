import React, { useState, useEffect, useMemo } from 'react';
import {
  ListGroup,
  Button,
  Table,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FaCopy } from 'react-icons/fa';
import McCampaignLink from './McCampaignLink';
import { useMcReportsApiData } from '../../hooks/useMcReportsApiData';

const McReportTable = ({
  report,
  combinedUrls,
  id,
  useCurrentDate,
  screenType,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const { campaign } = useMcReportsApiData(id);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const totalClicks = useMemo(
    () => combinedUrls.reduce((acc, url) => acc + url.total_clicks, 0),
    [combinedUrls]
  );

  const emailsSent = report.emails_sent || report.emailsSent || 0;
  const uniqueOpens = report.opens?.unique_opens || report.unique_opens || 0;
  const openRate = uniqueOpens / emailsSent;

  const calculatedClickRate = useMemo(() => {
    if (uniqueOpens === 0) return 0;
    return (totalClicks / uniqueOpens) * 100;
  }, [totalClicks, uniqueOpens]);

  const sendDate = new Date(report.send_time);

  const sendDateLabel = sendDate < new Date() ? 'Sent On' : 'Sending On';

  const displayDate = useCurrentDate ? new Date() : new Date(report.createdAt);

  const handleCopy = () => {
    const campaignLink =
      campaign?.long_archive_url || campaign.long_archive_url;
    const listName =
      report.list_name === 'Industry Marketing blasts'
        ? 'Marketing'
        : report.list_name === 'Online News'
        ? 'News'
        : report.list_name === 'LSA Previews, Updates, Deadlines'
        ? 'Updates'
        : report.list_name === 'LSA Renew'
        ? 'Renew'
        : report.list_name;
    const reportData = [
      listName,
      new Date(report.send_time).toLocaleDateString(),
      `${report.subject_line} ${
        report.preview_text !== '' ? `/ ${report.preview_text}` : ''
      }`,
      displayDate.toLocaleDateString(),
      emailsSent.toLocaleString(),
      uniqueOpens.toLocaleString(),
      (openRate * 100).toFixed(2) + '%',
      totalClicks.toLocaleString(),
      calculatedClickRate.toFixed(2) + '%',
      campaignLink,
    ].join('\t');

    navigator.clipboard.writeText(reportData);
  };

  return (
    <ListGroup.Item className='mt-3'>
      <Button
        variant='secondary'
        className='btn-sm mb-3'
        aria-label='Copy Table'
        onClick={handleCopy}
      >
        <FaCopy />{' '}
        {screenType === 'realtime'
          ? 'Copy Realtime Unformatted Table Data for Spreadsheet'
          : screenType === 'saved'
          ? 'Copy Saved Unformatted Table Data for Spreadsheet'
          : 'Copy Unformatted Table Data for Spreadsheet'}
      </Button>
      {!isMobile && (
        <Table responsive bordered className='table-sm' id='report-table'>
          <thead>
            <tr>
              <th>List</th>

              <th>{sendDateLabel}</th>
              <th>Subject / Preview</th>
              <th>{useCurrentDate ? 'Today' : 'Saved'}</th>
              <th>Sent</th>
              <th>Opened</th>
              <th>Open%</th>
              <th>Clicked</th>
              <th>Click%</th>
              <th>Campaign Link</th>
            </tr>
          </thead>
          <tbody>
            <tr id='report-table-row'>
              <td>{report.list_name}</td>
              <td>
                {' '}
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip id={`tooltip-send-date-${report._id}`}>
                      {sendDate.toLocaleTimeString()}
                    </Tooltip>
                  }
                >
                  <span>{sendDate.toLocaleDateString()}</span>
                </OverlayTrigger>
              </td>
              <td>
                {report.subject_line}{' '}
                {report.preview_text !== '' && `/ ${report.preview_text}`}
              </td>
              <td>
                {' '}
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip id={`tooltip-display-date-${report._id}`}>
                      {displayDate.toLocaleTimeString()}
                    </Tooltip>
                  }
                >
                  <span>{displayDate.toLocaleDateString()}</span>
                </OverlayTrigger>
              </td>
              <td>{emailsSent.toLocaleString()}</td>
              <td>{uniqueOpens.toLocaleString()}</td>
              <td>{(openRate * 100).toFixed(2)}%</td>
              <td>{totalClicks.toLocaleString()}</td>
              <td>{calculatedClickRate.toFixed(2)}%</td>
              <td>
                <McCampaignLink id={id} className='custom-link' />
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </ListGroup.Item>
  );
};

export default McReportTable;
